import React, { useState, useEffect } from 'react';
import logo from './assets/icon/logo.svg';
import './assets/css/App.css';
import './assets/css/project.css';
import icon_user from './assets/icon/icon_user.png';
import share from './assets/icon/share.svg';
import edit from './assets/icon/edit.svg';
import icon_iq from './assets/icon/photo_2024-09-19_17-28-19.jpg';
import icon_skill from './assets/icon/icons-it/PHP-Light.svg';
import icon_skill_js from './assets/icon/icons-it/NodeJS-Light.svg';
import icon_skill_Linux from './assets/icon/icons-it/Linux-Light.svg';
import Menu from "./components/Menu";
import Popup from "./components/popup";
import PopupShare from "./components/PopupShare";

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupShare, setShowPopupShare] = useState(false);
  const [popupText, setPopupText] = useState({ title: '', text: '' });
  const [hideContent, setHideContent] = useState(false); // Для управления скрытием контента
  const [allProjects, setShowAllProjects] = useState(false); // Для управления скрытием контента
  const [pages, setPages] = useState(false); // Для управления скрытием контента
  const [selectedText, setSelectedText] = useState(''); // Хранение текста выбранного блока
  const [fadeClass, setFadeClass] = useState('fade-in'); // Класс для анимации
  const [contentData, setContentData] = useState([
    {
      id: 'myWorks',
      title: 'Мои работы 👻',
      text: 'Мне 45 лет, я работаю на данный момент в Google. Всю свою жизнь я мечтал построить карьеру программиста. В свои 15 лет я уже понимал что такое алгоритмы и учил первый язык паскаль.',
      type: 1
    },
    {
      id: 'myLevel',
      title: 'Мой опыт 👽',
      text: 'Я работал с такими компаниями как Google, Vk, Yandex',
      type: 0
    },
    {
      id: 'byMe',
      title: 'Мой стэк 💡',
      text: 'Для разработки я использую React Js / Php / Java',
      type: 0
    },
    {
      id: 'myContacts',
      title: 'Мои контакты 🤖',
      text: 'Привет, я пью пиво ругаюсь матом. В целом все что я сделал полезного в этой жизни. Бла бла бла бла бла бла бла бла бла Основная моя область работы, это пальцы. Они проникают женщин а те в восторге от меня',
      type: 0
    },
    {
      id: 'myProjects',
      title: 'Мои проекты 💊',
      text: 'Привет, я пью пиво ругаюсь матом. В целом все что я сделал полезного в этой жизни. Бла бла бла бла бла бла бла бла бла Основная моя область работы, это пальцы. Они проникают женщин а те в восторге от меня',
      type: 0
    }
  ]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Функция для обработки клика с передачей id и текста
  const handleContentWorkClick = (id, text, page) => {
    setFadeClass('fade-out'); // Начинаем анимацию исчезновения
    setTimeout(() => {
      setSelectedText(text); // Устанавливаем текст выбранного блока
      setHideContent(true); // Скрываем остальной контент
      if(page === 'allProject')
      {
        handleSetPage('allProject');
      }
      setShowAllProjects(false);
      setFadeClass('fade-in'); // Возвращаем анимацию появления
    }, 500); // Задержка должна совпадать с продолжительностью анимации
  };

  const handleGetProject = (id, text, page) => {
    handleContentWorkClick(id, text, page)
  };

  const handleSetPage = (page) => {
    setPages(page);
  };
  const handleAllProject = () => {
    setFadeClass('fade-out'); // Начинаем анимацию исчезновения
    setTimeout(() => {
      setShowAllProjects(true); // Скрываем остальной контент
      setFadeClass('fade-in'); // Возвращаем анимацию появления
    }, 500); // Задержка должна совпадать с продолжительностью анимации
  };

  // Функция для возврата ко всему контенту
  const handleBackClick = (page) => {
    setFadeClass('fade-out'); // Анимация исчезновения
    setTimeout(() => {
      setHideContent(false); // Показываем весь контент
      setSelectedText(''); // Очищаем выбранный текст
      if(pages === 'allProject')
      {
        setPages(false);
        setShowAllProjects(true);
      } else {
        setShowAllProjects(false);
      }
      setFadeClass('fade-in'); // Возвращаем анимацию появления
    }, 500); // Задержка должна совпадать с продолжительностью анимации
  };
  function key_edit(id){
      var currentItem = contentData.find(item => item.id === id);
      setPopupText({ id: currentItem.id, title: currentItem.title, text: currentItem.text });
      setShowPopup(true);
  }
  const onUpdateContent = (id, newTitle, newContent) => {
    const updatedContentData = contentData.map((item) => {
      if (item.id === id) {
        return { ...item, title: newTitle, text: newContent };
      }
      return item;
    });
    setContentData(updatedContentData);
  };
  useEffect(() => {
  }, [contentData]);

  useEffect(() => {
    const contentEditsShare = document.querySelectorAll('.content-share');
    contentEditsShare.forEach((element) => {
      element.addEventListener('click', (event) => {
        setShowPopupShare(true);
      });
    });
  }, [contentData]);

  return (
      <div className="container">
        {showPopup && <Popup onClose={handleClosePopup} text={popupText} onUpdateContent={onUpdateContent} />}
        {showPopupShare && <PopupShare onClose={handleClosePopup} />}
        <div className="user-info-container">
          <div className="icon-skill-container">
            <div className="icon-skill">
              <img src={icon_skill} alt="logo" />
              <img src={icon_skill} className="icon-skill-blur" alt="logo" />
            </div>
            <div className="icon-skill">
              <img src={icon_skill_js} alt="logo" />
              <img src={icon_skill_js} className="icon-skill-blur" alt="logo" />
            </div>
          </div>
          <img src={icon_user} className="App-logo-blur" alt="logo" />
          <img src={icon_user} className="App-logo" alt="logo" />
          <div className="user_name">LilNLuv</div>
          <div className="user_info">Привет, меня зовут LilNLuv, опытный программист с большим желанием создавать инновационные решения. </div>
          <Menu />
        </div>
        <div className={`container-content 
                 ${allProjects ? 'container-all-project' : ''} 
                 ${hideContent ? '' : (allProjects ? '' : 'container-content-p')} 
                 ${fadeClass}`}>
          {allProjects ? (
              <>
                <button onClick={handleBackClick} className="back-button back-button-all-projects">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill-rule="evenodd" d="M16.942 6.329a1.2 1.2 0 0 1 1.697 0l2.546 2.546a1.2 1.2 0 0 1 0 1.697l-5.43 5.43 5.43 5.43a1.2 1.2 0 0 1 0 1.697l-2.546 2.545a1.2 1.2 0 0 1-1.697 0L7.27 16.002z" clip-rule="evenodd"></path></svg>
                </button>
                <div className="content-all-project">
                  <div className="content-blocks content-blocks-all-project">
                    <div
                        className="content-blocks-element content-work mb50"
                        onClick={() => handleContentWorkClick(1, 'Vk Video 1', 'allProject')} // Передаем id и текст
                    >
                      <img className="overlay-img-blur" src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                      <div className="content-block">
                        <img src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                      </div>
                      <p className="text content-work-text">Vk Video 1</p>
                    </div>
                    <div
                        className="content-blocks-element content-work mb50"
                        onClick={() => handleContentWorkClick(2, 'Vk Video 2', 'allProject')} // Передаем id и текст
                    >
                      <img className="overlay-img-blur" src="https://dev.vk.com/images/snippet.png" alt="VK" />
                      <div className="content-block">
                        <img src="https://dev.vk.com/images/snippet.png" alt="VK" />
                      </div>
                      <p className="text content-work-text">Vk Video 2</p>
                    </div>
                    <div
                        className="content-blocks-element content-work mb50"
                        onClick={() => handleContentWorkClick(3, 'Vk Video 3', 'allProject')} // Передаем id и текст
                    >
                      <img className="overlay-img-blur" src="https://www.iguides.ru/upload/medialibrary/a6e/ut7k5ggf7do2czww5fofdwvlu7sj4v4x.png" alt="Yandex" />
                      <div className="content-block">
                        <img src="https://www.iguides.ru/upload/medialibrary/a6e/ut7k5ggf7do2czww5fofdwvlu7sj4v4x.png" alt="Yandex" />
                      </div>
                      <p className="text content-work-text">Vk Video 3</p>
                    </div>
                    <div
                        className="content-blocks-element content-work mb50"
                        onClick={() => handleContentWorkClick(1, 'Vk Video 1', 'allProject')} // Передаем id и текст
                    >
                      <img className="overlay-img-blur" src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                      <div className="content-block">
                        <img src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                      </div>
                      <p className="text content-work-text">Vk Video 1</p>
                    </div>
                    <div
                        className="content-blocks-element content-work mb50"
                        onClick={() => handleContentWorkClick(1, 'Vk Video 1', 'allProject')} // Передаем id и текст
                    >
                      <img className="overlay-img-blur" src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                      <div className="content-block">
                        <img src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                      </div>
                      <p className="text content-work-text">Vk Video 1</p>
                    </div>

                    <div
                        className="content-blocks-element content-work mb50 add-blocks"
                         // Передаем id и текст
                    >
                      <div className="add-block">
                        <p>Добавить</p>
                      </div>
                    </div>

                  </div>
                </div>
              </>
          ) : (
              hideContent ? (
                    <>
                      <div className="content-select-blocks">
                        <div className="content-project-title ">

                          <img src={icon_iq} alt="Google" />
                          <div className="content-select-info-block teams-conteiner">
                            <div className="teams">
                              <div className="team-user">
                                <span className="tooltip">php 8.1</span>
                                <img src={icon_skill} />

                              </div>
                              <div className="team-user">
                                <span className="tooltip">JS 3.4</span>
                                <img src={icon_skill_js} />

                              </div>
                              <div className="team-user">
                                <span className="tooltip">Linux 4.0</span>
                                <img src={icon_skill_Linux} />

                              </div>
                              <div className="team-user">
                                <div className="team-user-add"><svg viewBox="0 0 20 20" class="w-[1em] h-[1em] inline-block text-sm" role="img" data-testid="PlusIcon"><g><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M9,0c-1.1,0-2,.9-2,2v5H2c-1.1,0-2,.9-2,2v2c0,1.1.9,2,2,2h5v5c0,1.1.9,2,2,2h2c1.1,0,2-.9,2-2v-5h5c1.1,0,2-.9,2-2v-2c0-1.1-.9-2-2-2h-5V2c0-1.1-.9-2-2-2h-2Z"></path></g></svg></div>
                              </div>
                            </div>
                          </div>
                          <div className="content-select-info-block teams-conteiner-left">
                            <div className="teams-head">
                              <p>Команда</p>
                            </div>
                            <div className="teams">
                              <div className="team-user">
                                <span className="tooltip">LilNLuv</span>
                                <img src={icon_user} />
                              </div>
                              <div className="team-user">
                                <div className="team-user-add"><svg viewBox="0 0 20 20" class="w-[1em] h-[1em] inline-block text-sm" role="img" data-testid="PlusIcon"><g><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M9,0c-1.1,0-2,.9-2,2v5H2c-1.1,0-2,.9-2,2v2c0,1.1.9,2,2,2h5v5c0,1.1.9,2,2,2h2c1.1,0,2-.9,2-2v-5h5c1.1,0,2-.9,2-2v-2c0-1.1-.9-2-2-2h-5V2c0-1.1-.9-2-2-2h-2Z"></path></g><title>У этого предмета есть дополнения</title></svg></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <button onClick={() => handleBackClick()} className="back-button">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill-rule="evenodd" d="M16.942 6.329a1.2 1.2 0 0 1 1.697 0l2.546 2.546a1.2 1.2 0 0 1 0 1.697l-5.43 5.43 5.43 5.43a1.2 1.2 0 0 1 0 1.697l-2.546 2.545a1.2 1.2 0 0 1-1.697 0L7.27 16.002z" clip-rule="evenodd"></path></svg>
                        </button>
                        <div className="container-content-p">
                          <div className="content-div">
                            <div className="content-title-container">
                              <div className="content-title-project">
                                Как мы аниме смотрели, а потом громко кричали
                                <div className="content-edit" ><img  src={edit} alt="logo" /></div>
                              </div>
                            </div>
                            <div className="content-title-container">
                              <div className="content-title">
                                Немного о нас 👻
                                <div className="content-edit" ><img  src={edit} alt="logo" /></div>
                              </div>

                            </div>
                            <div className="content-text">Мы разрабатываем уже давно проекты, мы собрали таких ребят типа ну прям гении</div>
                          </div>
                        </div>


                      </div>
                    </>
                ) : (
                    contentData.map((item, index) => (
                        <div className="content-div" key={index} id={item.id}>
                          <div className="content-title-container">
                            <div className="content-title">
                              {item.title}
                              <div className="content-edit" onClick={() => key_edit(item.id)} id={item.id}><img id={item.id} src={edit} alt="logo" /></div>
                            </div>
                            {index === 0 && <div className="content-share"><img src={share} alt="logo" /></div>}
                          </div>
                          <div className="content-text">{item.text}</div>
                          {item.type === 1 && (
                              <>
                                <div className="content-blocks">
                                  <div
                                      className="content-blocks-element content-work"
                                      onClick={() => handleGetProject(1, 'Vk Video 1')} // Передаем id и текст
                                  >
                                    <img className="overlay-img-blur" src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                                    <div className="content-block">
                                      <img src="https://static-00.iconduck.com/assets.00/google-icon-512x512-wk1c10qc.png" alt="Google" />
                                    </div>
                                    <p className="text content-work-text">Vk Video 1</p>
                                  </div>
                                  <div
                                      className="content-blocks-element content-work"
                                      onClick={() => handleGetProject(2, 'Vk Video 2')} // Передаем id и текст
                                  >
                                    <img className="overlay-img-blur" src="https://dev.vk.com/images/snippet.png" alt="VK" />
                                    <div className="content-block">
                                      <img src="https://dev.vk.com/images/snippet.png" alt="VK" />
                                    </div>
                                    <p className="text content-work-text">Vk Video 2</p>
                                  </div>
                                  <div
                                      className="content-blocks-element content-work"
                                      onClick={() => handleGetProject(3, 'Vk Video 3')} // Передаем id и текст
                                  >
                                    <img className="overlay-img-blur" src="https://www.iguides.ru/upload/medialibrary/a6e/ut7k5ggf7do2czww5fofdwvlu7sj4v4x.png" alt="Yandex" />
                                    <div className="content-block">
                                      <img src="https://www.iguides.ru/upload/medialibrary/a6e/ut7k5ggf7do2czww5fofdwvlu7sj4v4x.png" alt="Yandex" />
                                    </div>
                                    <p className="text content-work-text">Vk Video 3</p>
                                  </div>
                                </div>
                                <div className="content-more">
                                  <a onClick={() => handleAllProject()} href="#">Больше проектов 🔥</a>
                                </div>
                              </>
                          )}
                        </div>
                    ))
              )
          )}
        </div>
      </div>
  );
}

export default App;
